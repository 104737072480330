import React, { useState } from "react";
import { AdminPageTemplate, ColumnToolTip, ModalEditUserStatus, } from "../../features";
import { ModalOutsourcePack } from "../../features/modals/common/outsourcePackModal";
import { useFunctionalityPagedCallOutflow } from "../hooks/functionalityCallOutflow";
import { ApiCallOutflowSchemaChangeStatus, ApiSchemaCallOutflowData, CallOutflowStatusType, } from "../../models/api/callOutflow";
import { getKeyByValue } from "../../utils";
import { useAppSelector, VRIAppStateType } from "../../core";
import { shallowEqual } from "react-redux";
import { ApiSchemaLanguagePackData } from "../../models/api";
import { useFunctionalityLanguagePack } from "../hooks";
import LoadingBar from "react-top-loading-bar";
import { AssetSvg } from "../../assets";
import { PaginationSettings } from '../../configs';
import ReactDOM from "react-dom";

const statusMap = new Map();
statusMap.set(1, true);
statusMap.set(2, false);

type Props = {
  companyId: string | null;
};
export function PageCallOutflow(props: Props): JSX.Element {
  const { companyId } = props;
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [callOutflowId, setCallOutflowId] = useState("");
  const [callOutflowStatus, setCallOutflowStatus] = useState(0);
  const [viewModalShow, setViewModalShow] = React.useState(false);
  const [editStatusModalShow, setEditStatusModalShow] = React.useState(false);
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const [dataToView, setDataToView] = useState<ApiSchemaLanguagePackData>(Object);
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [searchText, setSearchText] = useState<string>("");


  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      ReactDOM.unstable_batchedUpdates(() => {
        setSearchText(e.target.value);
        setPageIndex(1);
      });
      setPageIndex(1);
    });
  };

  const currentUserInfo = useAppSelector(
    (state: VRIAppStateType) => ({
      companyId: state.auth.profile?.companyId || "",
    }),
    shallowEqual
  );

  const onViewHandler = (id?: string) => {
    onLoadLanguagePackById(id ?? "").then((data) => {
      if (data) setDataToView(data);
      setViewModalShow(true);
    });
  };
  const onChangeUserStatus = (status: number) => {
    setCallOutflowStatus(status);
  };

  // const { pagedCallOuflowListCount, onChangeStatus, pagedcallOutflowList } = useFunctionalityCallOutflow({
  const { pagedcallOutflowList, pagedCallOuflowListCount, onChangeStatus } =
    useFunctionalityPagedCallOutflow({
      companyId: companyId ?? currentUserInfo.companyId,
      pageSize: pageSize,
      pageNo: pageIndex,
      searchText: searchText?.trimStart(),
      setLoaderProgress
    });



  const { onLoadLanguagePackById } = useFunctionalityLanguagePack({
    companyId: companyId ?? currentUserInfo.companyId,
    setLoaderProgress: setLoaderProgress,
  });
  
  let enableStatusCount: number = 0;
  let checkEnableAll: boolean = false;

  if (pagedcallOutflowList) {
    // counting enable
    enableStatusCount = pagedcallOutflowList.reduce(
      (n, e) => (e.status ? n + 1 : n),
      0
    );

    if (enableStatusCount === pagedcallOutflowList.length) {
      checkEnableAll = true;
    }
  }
  const enableAllToggleHandler = () => {
    checkEnableAll = !checkEnableAll;

    if (pagedcallOutflowList) {
      const callOutflowIds = pagedcallOutflowList
        .map((id) => id.callInflowId)
        .filter((callId, index, data) => data.indexOf(callId) === index);

      const statusModelOnAllEnable: ApiCallOutflowSchemaChangeStatus = {
        callInflowIds: [...callOutflowIds],
        companyId: companyId ?? currentUserInfo.companyId,
        status: statusMap.get(Number(checkEnableAll) === 0 ? 2 : 1),
      };

      onChangeStatus(statusModelOnAllEnable);
    }
  };

  const onUpdateUserStatus = (status: number) => {

    setCallOutflowStatus(status);
    const statusModel: ApiCallOutflowSchemaChangeStatus = {
      callInflowIds: [callOutflowId],
      companyId: companyId ?? currentUserInfo.companyId,
      status: statusMap.get(status),
    };
    onChangeStatus(statusModel).then(() => {
      setEditStatusModalShow(false);
    });
  };

  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };

  const callOutflowTableColumns = [
    {
      name: <ColumnToolTip data="Name" />,
      selector: (row: ApiSchemaCallOutflowData) => {
        return <ColumnToolTip data={row.callCenterName} />;
      },
    },
    {
      name: <ColumnToolTip data="Outsource Vri" />,
      allowOverflow: true,
      cell: (row: any) => {
        return row.vriPackId ? (
          <>
            <div
              className="btn svg-color pl-0"
              title="View"
              onClick={() => onViewHandler(row.vriPackId)}
            >
              <span >
                <AssetSvg.ViewProfile />
              </span>
            </div>
          </>
        ) : (
          <></>
        );
      },
    },
    {
      name: <ColumnToolTip data="Outsource Opi" />,
      allowOverflow: true,
      cell: (row: ApiSchemaCallOutflowData) => {
        return row.opiPackId ? (
          <>
            <div
              className="btn svg-color pl-0"
              title="View"
              onClick={() => onViewHandler(row.opiPackId)}
            >
              <span >
                <AssetSvg.ViewProfile />
              </span>
            </div>
          </>
        ) : (
          <></>
        );
      },
    },
    {
      name: <ColumnToolTip data="Status" />,
      allowOverflow: true,
      cell: (row: ApiSchemaCallOutflowData) => {
        return (
          <>
            <span
              className={`badge p-1 rounded-0 text-uppercase text-white ${row?.status?.toString() === CallOutflowStatusType.Enabled
                ? `bg-success`
                : row?.status?.toString() === CallOutflowStatusType.Disabled
                  ? `bg-secondary`
                  : row?.status?.toString() === CallOutflowStatusType.Pending
                    ? `bg-orange`
                    : ""
                }`}
            >
              {" "}
              {getKeyByValue(CallOutflowStatusType, row?.status?.toString())}
            </span>
            <div
              className="btn svg-color"
              title="Edit"
              onClick={() => {
                setCallOutflowId(row?.callInflowId!);
                setCallOutflowStatus(Number(row.status) === 0 ? 2 : 1);
                setEditStatusModalShow(true);
              }}
            >
              <span >
                <AssetSvg.Edit />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <div className="row flex-wrap">
        <div className="col">
          <AdminPageTemplate
            loaderProgress={loaderProgress}
            pageTitle="Call Outflow"
            tableHeaders={callOutflowTableColumns}
            tableData={pagedcallOutflowList}
            setCreateModalShow={setViewModalShow}
            enableAllToggle={checkEnableAll}
            enableAllToggleHandler={enableAllToggleHandler}
            searchText={searchText}
            handleSearch={handleSearch}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={pageSize}
            count={pagedCallOuflowListCount}
            onChangePageIndex={onChangePageIndex}
            onChangePageSize={onChangePageSize}
            hasPagingData={hasPagingData}
            setHasPagingData={setHasPagingData}
          />
        </div>
      </div>
      <ModalOutsourcePack
        show={viewModalShow}
        handleShow={setViewModalShow}
        header="Outsource pack for VRI"
        dataToView={dataToView}
      />
      <ModalEditUserStatus
        show={editStatusModalShow}
        handleShow={setEditStatusModalShow}
        onChangeHandler={onChangeUserStatus}
        checkedItem={callOutflowStatus}
        onSaveHandler={onUpdateUserStatus}
      />
    </>
  );
}
