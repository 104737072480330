import { useState } from "react";
import { Modal } from "react-bootstrap";
import { UserStatusType } from "../../models";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  onChangeHandler: any;
  checkedItem: number;
  onSaveHandler: any;
};

export function ModalEditUserStatus(props: Props): JSX.Element {
  return (
    <Modal show={props.show} onHide={() => props.handleShow(false)} centered>
        <Modal.Header closeButton>
        <Modal.Title>Change Status</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
            defaultChecked={UserStatusType.Enabled === props.checkedItem}
            onClick={() => props.onChangeHandler(UserStatusType.Enabled)}
          />
          <label className="form-check-label">Enabled</label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault2"
            onClick={() => props.onChangeHandler(UserStatusType.Disabled)}
            defaultChecked={UserStatusType.Disabled === props.checkedItem}
          />
          <label className="form-check-label">Disabled</label>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div onClick={() => props.handleShow(false)} className="btn btn-link text-uppercase text-site">Cancel</div>
        <button className="btn btn-primary text-uppercase user-from-submit-btn" onClick={() => { props.onSaveHandler(props.checkedItem) }}>Save</button>
      </Modal.Footer>

    </Modal>
  );
}
