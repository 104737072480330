import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import {
  PlatformApi,
  AppConstants,
  AppRouteUi,
  topLoaderProgress,
  CompanyType,
} from "../../configs";
import { AxiosAuth, useAppSelector, VRIAppStateType} from "../../core";
import { companyMapper } from "../../helpers";
import { ApiResponse, WithPagination } from "../../models/api/common";
import {
  ApiSchemaCompanyByIdData,
  ApiSchemaCompanyData,
  ApiSchemaUpdateCompanySkill,
  CompanyAppointmentCategoryRequestModel,
  CompanyAppointmentDescriptionRequestModel,
  CompanyFormUpdateModel,
  CompanyModalityRequestModel,
  CompanyUpdateModel,
} from "../../models/api/company";
import { OptionMapper, UtilsJQuery } from "../../utils";
import { ApiSchemaTinData } from "../../models";
import { ModalityRequestModel } from "../../models/api/modality";
import { ExistModel } from "../../models/api/Operator";

type PagedProps = {
  pageSize: number;
  pageNo: number;
  searchText?: string;
  setLoaderProgress: any;
  parentCompanyId: string;
};

let pagedCompanyUrl: string;

export function useFunctionalityPagedCompany(props: PagedProps) {
  const { pageSize, pageNo, searchText, setLoaderProgress, parentCompanyId } =
    props;
  // const [pagedCompanyListCount, setPagedCompanyListCount] = useState<number>(0);

  pagedCompanyUrl = PlatformApi.Company.GetAll(
    parentCompanyId,
    pageSize,
    pageNo,
    searchText
  );

  const { data: apiCompanyData } = useSWR<{
    data: ApiSchemaCompanyData[];
    count: number;
  }>(
    pagedCompanyUrl,
    async () => {
      setLoaderProgress(topLoaderProgress.start);
  
      try {
        const response = await AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaCompanyData[]>>>(
          pagedCompanyUrl
        );
        setLoaderProgress(topLoaderProgress.complete);
  
        return {
          data: response.data.data.data,
          count: response.data.data.count,
        };
      } catch (error) {
        setLoaderProgress(topLoaderProgress.complete);
        console.error(error);
        throw error; 
      }
    }
  );
  
  const pagedCompanyList = useMemo(() => {
    return Array.isArray(apiCompanyData?.data) ? apiCompanyData.data : [];
  }, [apiCompanyData]);
  
  const pagedCompanyListCount = apiCompanyData?.count ?? 0;
  
  return {
    pagedCompanyListCount,
    pagedCompanyList,
  };
  
}
type Props = {
  companyType?: CompanyType | null;
  setLoaderProgress?: any;
  parentId?: string | "";
  companyId?: string;
  shouldCompanyGetAllInvoke?: boolean
};

export function useFunctionalityCompany(props: Props) {
  const { companyType, setLoaderProgress, parentId, companyId, shouldCompanyGetAllInvoke = false } = props;
  const formData = useAppSelector((state: VRIAppStateType) => state.company);

  const profilePhotoUrl = PlatformApi.Company.UploadLogo(companyId ?? '');
  const { data: apiCompanyById } = useSWR<ApiSchemaCompanyByIdData>(
    companyId && shouldCompanyGetAllInvoke ? PlatformApi.Company.GetById(companyId) : null,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<ApiSchemaCompanyByIdData>>(
        PlatformApi.Company.GetById(companyId ?? "")
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          return r.data.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  const companyByIdData = useMemo(() => {
    const data: ApiSchemaCompanyByIdData =
      typeof apiCompanyById === "object" ? apiCompanyById : {};
    return data;
  }, [apiCompanyById]);

  const onCreateCompany = React.useCallback(async () => {
    const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.post(
        PlatformApi.Company.Create(),
        companyMapper(formData, companyType ?? 0, parentId)
      );

      if (response.status === AppConstants.Api.StatusOK) {
        mutate(pagedCompanyUrl);
        toast.success("Company Creation Successful");
      }
      return Promise.resolve(response);
    } catch (e) {
      toast.error("Company Creation Failed");
    }
    btnLoading.stop?.();


  }, []);

  const onDelete = React.useCallback(async (companyId: string) => {
    const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.delete(
        PlatformApi.Company.Delete(companyId ?? ""),
      );

      if (response.status === AppConstants.Api.StatusOK) {
        mutate(pagedCompanyUrl);
        toast.success("Company Deleted Successful");
      }
      return Promise.resolve(response);
    } catch (e) {
      toast.error("Company Deleted Failed");
    }
    btnLoading.stop?.();


  }, []);

  const onUpdateCompanyModality = React.useCallback(async (companyId: string, body: CompanyModalityRequestModel) => {
    try {
      const res = await AxiosAuth.put(PlatformApi.Company.UpdateCompanyModality(companyId), body);

      if (res?.status === AppConstants.Api.StatusOK) {
        toast.success("Settings have been updated.");
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject();
    }
  }, []);

  const onUpdateCompanyAppointmentCategory = React.useCallback(async (companyId: string, body: CompanyAppointmentCategoryRequestModel) => {
    try {
      const res = await AxiosAuth.put(PlatformApi.Company.UpdateCompanyAppointmentCategory(companyId), body);

      if (res?.status === AppConstants.Api.StatusOK) {
        toast.success("Settings have been updated.");
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject();
    }
  }, []);

  const onUpdateCompanyAppointmentDescription = React.useCallback(async (companyId: string, body: CompanyAppointmentDescriptionRequestModel) => {
    try {
      const res = await AxiosAuth.put(PlatformApi.Company.UpdateCompanyAppointmentDescription(companyId), body);

      if (res?.status === AppConstants.Api.StatusOK) {
        toast.success("Settings have been updated.");
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject();
    }
  }, []);

  const onUpdateCompanySkill = React.useCallback(async (companyId: string, body: ApiSchemaUpdateCompanySkill) => {
    // const resellerUrlToMutate = PlatformApi.Reseller.GetById(companyId);

    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.put(PlatformApi.Company.UpdateCompanySkill(companyId), body);

      if (res?.status === AppConstants.Api.StatusOK) {
        toast.success("Settings have been updated.");
        // mutate(resellerUrlToMutate);
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject();
    }
  }, []);


  const updateCompanyProfile = React.useCallback(async (id: string, form: CompanyUpdateModel) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();

    const newForm: CompanyFormUpdateModel = {
      name: form.name,
      timeZone: form.timeZone,
      currency: form.currency,
      description: form.address.description,
      city: form.address.city,
      countryId: form.address.countryId,
      stateId: form.address.stateId,
      email: form.address.email,
      fax: form.address.fax,
      phone: form.address.phone,
      website: form.address.website,
      zipCode: form.address.zipCode
    }

    let response;
    try {
      response = await AxiosAuth.put(PlatformApi.Company.UpdateCompanyProfile(id || ""), newForm);
      if ((response.status = AppConstants.Api.StatusOK)) {
        mutate(PlatformApi.Company.GetById(id || ''));
        mutate(PlatformApi.Reseller.GetById(id ?? ""));
        toast.success("Update Company Details success");
      }
    } catch (e) {
      toast.error("Failed to Update Company Details");
      console.log(e);
    }
    btnLoading.stop?.();
    return response;
  }, []);



  const onUploadLogo = useCallback(async (form: FormData, id: string) => {

    const url = PlatformApi.Company.UploadLogo(id ?? '');
    const btnLoading = UtilsJQuery.Ladda(".upload-button");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.post<FormData>(
        url,
        form
      );

      if (res.status === AppConstants.Api.StatusOK) {
        // mutate(urlToMutate);
        toast.success("Uploaded successfully");
      }
    } catch (e) {
      toast.error("Upload failed");
    }
    btnLoading.stop?.();

    return Promise.resolve(null);
  }, []);
  const onUpdateBookingPolicy = useCallback(async (companyId: string, form: FormData) => {
    const btnLoading = UtilsJQuery.Ladda(".upload-button");
    btnLoading.start?.();
    const url = PlatformApi.Company.UpdateCompanyBookingPolicy(companyId ?? '');

    try {
      const res = await AxiosAuth.put<FormData>(
        url,
        form
      );

      if (res.status === AppConstants.Api.StatusOK) {
        // mutate(urlToMutate);
        toast.success("Uploaded successfully");
      }
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject(null);
      toast.error("Upload failed");
    }
    btnLoading.stop?.();


  }, []);

  return {
    onCreateCompany,
    onDelete,
    companyByIdData,
    apiCompanyById,
    onUpdateCompanySkill,
    onUpdateCompanyModality,
    onUpdateCompanyAppointmentCategory,
    onUpdateCompanyAppointmentDescription,
    updateCompanyProfile,
    onUploadLogo,
    onUpdateBookingPolicy
  };
}

export function useFunctionalityCompanyProfileDetails(props: Props) {
  const { companyId } = props;

  const companyProfileDetailsUrl = PlatformApi.Company.GetCompanyProfileById(companyId ?? '');

  const onCompanyProfileDetails = useCallback(async () => {
    try {
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaCompanyByIdData>>(companyProfileDetailsUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(companyProfileDetailsUrl);
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanyProfileDetails
  };
}

export function useFunctionalityCompanyNames(includeReseller?: boolean) {

  const onCompanyNames = useCallback(async (companyId: string) => {

    const companyNamesUrl = PlatformApi.Company.GetCompanyNames(companyId ?? '', includeReseller);
    try {
      const res = await AxiosAuth.get<ApiResponse<any>>(companyNamesUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        // mutate(companyProfileDetailsUrl);
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanyNames
  };
}

export function useFunctionalityCompanyContractDetails(props: Props) {
  const { companyId, setLoaderProgress } = props;

  const companyContractDetailsUrl = PlatformApi.Company.GetContractById(companyId ?? '');

  const onCompanyContractDetails = useCallback(async () => {
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaCompanyByIdData>>(companyContractDetailsUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(companyContractDetailsUrl);
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanyContractDetails
  };
}

export function useFunctionalityCompanyIvrDetails(props: Props) {
  const { companyId, setLoaderProgress } = props;

  const companyIvrDetailsUrl = PlatformApi.Company.GetIvrById(companyId ?? '');

  const onCompanyIvrDetails = useCallback(async () => {
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaCompanyByIdData>>(companyIvrDetailsUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        // mutate(companyIvrDetailsUrl);
        // return Pro
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanyIvrDetails
  };
}

export function useFunctionalityCompanyPackageDetails(props: Props) {
  const { companyId, setLoaderProgress } = props;

  const companyPackageDetailsUrl = PlatformApi.Company.GetPackageById(companyId ?? '');

  const onCompanyPackageDetails = useCallback(async () => {
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaCompanyByIdData>>(companyPackageDetailsUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(companyPackageDetailsUrl);
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanyPackageDetails
  };
}

export function useFunctionalityCompanySkillDetails(props: Props) {
  const { companyId, setLoaderProgress } = props;

  const companySkillDetailsUrl = PlatformApi.Company.GetSkillById(companyId ?? '');

  const onCompanySkillDetails = useCallback(async () => {
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaCompanyByIdData>>(companySkillDetailsUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(companySkillDetailsUrl);
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanySkillDetails
  };
}

export function useFunctionalityCompanyContactDetails(props: Props) {
  const { companyId, setLoaderProgress } = props;

  const companyContactDetailsUrl = PlatformApi.Company.GetContactById(companyId ?? '');

  const onCompanyContactDetails = useCallback(async () => {
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaCompanyByIdData>>(companyContactDetailsUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(companyContactDetailsUrl);
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanyContactDetails
  };
}

export function useFunctionalityCompanySettingsDetails(props: Props) {
  const { companyId, setLoaderProgress } = props;

  const companySettingsDetailsUrl = PlatformApi.Company.GetSettingsById(companyId ?? '');

  const onCompanySettingsDetails = useCallback(async () => {
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaCompanyByIdData>>(companySettingsDetailsUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(companySettingsDetailsUrl);
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanySettingsDetails
  };
}

export function useFunctionalityCompanyModalityDetails(props: Props) {
  const { companyId, setLoaderProgress } = props;

  const companyModalityDetailsUrl = PlatformApi.Company.GetModalityById(companyId ?? '');

  const onCompanyModalityDetails = useCallback(async () => {
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaCompanyByIdData>>(companyModalityDetailsUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(companyModalityDetailsUrl);
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanyModalityDetails
  };
}

export function useFunctionalityCompanyAppointmentTypeDetails(props: Props) {
  const { companyId, setLoaderProgress } = props;

  const companyAppointmentTypeDetailsUrl = PlatformApi.Company.GetAppointmentTypeById(companyId ?? '');

  const onCompanyAppointmentTypeDetails = useCallback(async () => {
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaCompanyByIdData>>(companyAppointmentTypeDetailsUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(companyAppointmentTypeDetailsUrl);
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanyAppointmentTypeDetails
  };
}

export function useFunctionalityCompanyAppointmentDescriptionDetails(props: Props) {
  const { companyId, setLoaderProgress } = props;

  const companyAppointmentDescriptionDetailsUrl = PlatformApi.Company.GetDescriptionById(companyId ?? '');

  const onCompanyAppointmentDescriptionDetails = useCallback(async () => {
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaCompanyByIdData>>(companyAppointmentDescriptionDetailsUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(companyAppointmentDescriptionDetailsUrl);
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onCompanyAppointmentDescriptionDetails
  };
}

type CompanyCodeProps = {
  code: string
};
export function useFunctionalityCompanyCodeExistence(props: CompanyCodeProps) {
  const { code } = props;

  const onCompanyCodeCheckExistence = React.useCallback(async () => {
    const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
    btnLoading.start?.();

    try {
      const codeUrl = PlatformApi.Company.IsCodeExist(code);
      const response = await AxiosAuth.get<ApiResponse<ExistModel>>(codeUrl);

      if (response.status === AppConstants.Api.StatusOK) {
        if (response?.data?.data) {
          return Promise.resolve(response);
        }
      }
    } catch (e) {
      toast.error("Company Creation Failed");
      return Promise.reject(e);
    }

    btnLoading.stop?.();
  }, []);

  return {
    onCompanyCodeCheckExistence
  };
}

export function useFunctionalityCompanyStatus() {
  const onCheckCompanyStatusIsActive = useCallback(async (companyId: string) => {
    const companyIsActiveUrl = PlatformApi.Company.IsCompanyStatusActive(companyId ?? '');
    try {
      const res = await AxiosAuth.get<ApiResponse<any>>(companyIsActiveUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        // mutate(companyProfileDetailsUrl);
        return Promise.resolve(res?.data);
      }
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onCheckCompanyStatusIsActive
  };
}

export function useFunctionalityChangeCompanyContact() {
  const onChangeCompanyContact = useCallback(async (companyId: string, userId: string) => {
    const changeCompanyContactUrl = PlatformApi.Company.ChangeCompanyContact(companyId ?? '', userId ?? '');

    const resellerUrlToMutate = PlatformApi.Reseller.GetById(companyId ?? '');

    const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.put<ApiResponse<any>>(changeCompanyContactUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(resellerUrlToMutate);
        toast.success("Successfully changed contact person");
        return Promise.resolve(res?.data);
      }
    } catch (e: any) {
      toast.error(e?.message);
      return Promise.reject(null);
    }
    btnLoading.stop?.();
  }, []);

  return {
    onChangeCompanyContact
  };
}