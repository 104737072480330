import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select, { createFilter } from "react-select";
import { FormErrorMessage } from "../../components";
import { InputFieldValidationErrorMessage, reactSelectFilterConfig } from "../../configs";
import { APISchemaCreateUser } from "../../models";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
import { useFunctionalityUser, useFunctionalityUser2 } from '../../pages/hooks/functionalityUser';
import { ApiDataMapper, ObjectToOptionMapper, UtilsJQuery } from "../../utils";
import { ExistModel } from "../../models/api/Operator";
import { useFunctionalityCheckEmailExistence } from "../../pages/hooks/functionalityCheckEmailExistence";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  companyId: string;
  adminRole: string;
};
export function ModalAddUser(props: Props): JSX.Element {
  const { companyId, show, handleShow, adminRole } = props;
  const roles: Object = ApiDataMapper.UserRoleOptionMapper(adminRole);
  const userRoles = ObjectToOptionMapper.mapperFunc(roles);
  const { onAddCompanyUser } = useFunctionalityUser2({ companyId });
  //@ts-ignore
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<APISchemaCreateUser>();
  const [email, setEmail] = useState("");
  const [retypeEmail, setRetypeEmail] = useState("");
  const { handleSpecialCharacterWhiteSpaces, useFunctionlityInputFieldValidation } = useFunctionalityInputChecker();
  const { onCheckEmailExistence, emailExistMsg, setEmailExistMsg } = useFunctionalityCheckEmailExistence();

  const modalHideHandler = () => {
    handleShow(false);
    setEmailExistMsg("");
  }
  const onCreateHandler = async (form: APISchemaCreateUser) => {
    const btnLoading = UtilsJQuery.Ladda(".next-btn");
    btnLoading.start?.();
    var mail = document?.getElementById("email") as HTMLInputElement;
    const finalEmailValidation = await checkEmailExistence(mail.value);
    btnLoading.stop?.();
    form = {
      ...form,
      firstName: form.firstName.trim(),
      lastName: form.lastName.trim()
    }
    if (
      emailExistMsg === ""
      && !finalEmailValidation
    ) {
      onAddCompanyUser(form)
        .then(() => modalHideHandler())
        .catch((e: any) => { console.error(e) })
    }
  };
  const checkEmailExistence = async (value: string) => {
    setEmail(value);
    const isEmailExist = await onCheckEmailExistence(value ?? "")?.then((res) => {
      const exist: ExistModel = res?.data!;
      return exist;
    })
      .catch((e: any) => {
        console.error(e);
      });
    return isEmailExist;
  }

  return (
    <Modal
      show={props.show}
      onHide={modalHideHandler}
      centered
      id="addCompanyUserModal"
    >
      {/* @ts-ignore */}
              {/*@ts-ignore */}
        <Modal.Header closeButton>
        <Modal.Title>Add User</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onCreateHandler)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  className="form-control"
                  placeholder="email@example.com"
                  {...register('email', {
                    required: true,
                  })}
                  onBlur={(e) => {
                    checkEmailExistence(e.target.value);
                  }}
                />
                {emailExistMsg && (
                  <FormErrorMessage message={emailExistMsg} />
                )}
                {errors.email?.type === "required" && <FormErrorMessage message="required" />}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Retype Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="email@example.com"
                  {...register('retypeEmail', { 
                    required: true,
                    onBlur: (e) => {
                      setRetypeEmail(e.target.value);
                    }
                  })}
                />
                {email !== retypeEmail && (
                  <FormErrorMessage
                    message={retypeEmail ? "Please enter the email again" : ""}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register('firstName', {
                    required: true,
                    // validate: handleSpecialCharacterWhiteSpaces
                    validate: useFunctionlityInputFieldValidation
                  })}
                />
                {
                  errors.firstName && errors.firstName.type == "validate" && (
                    <FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />
                  )
                }
                {errors.firstName && errors.firstName.type == "required" && <FormErrorMessage message="required" />}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register('lastName', {
                    required: true,
                    //validate: handleSpecialCharacterWhiteSpaces
                    validate: useFunctionlityInputFieldValidation
                  })}
                />
                {
                  errors.lastName && errors.lastName.type == "validate" && (
                    <FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />
                  )
                }
                {errors.lastName && errors.lastName.type == "required" && <FormErrorMessage message="required" />}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  User Role
                </label>
                <input
                  type="text"
                  className="d-none"
                  {...register('userRole', {
                    required: true,
                  })}
                />
                <Select
                  className={` ${errors.userRole?.type == "required" ? 'field-required' : ''} `}

                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder="Select role"
                  options={userRoles}
                  filterOption={createFilter(reactSelectFilterConfig)}
                  onChange={(value) => {
                    setValue("userRole", value?.value);
                  }}
                />
                {errors.userRole && <FormErrorMessage message="required" />}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={modalHideHandler}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase company-user-from-submit-btn"
          >
            Create
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
