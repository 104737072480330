import React, { useEffect, useState } from "react";
import { useAppSelector, VRIAppStateType } from "../../core/Redux/ReduxStore";
import {ModalCalendarUpcomingSchedule, TopNavPublic } from "../../features";
import { ApiSchemaUpdateUser, ApiSchemaUserDetailsData, ConsumerConferenceData } from "../../models";
import { useFunctionalityConsumerConference} from "../hooks";
import { AppRouteUi } from "../../configs";
import UpcomingSchedule from "../../features/UserCalendar/upcomingSchedule";
import { useFunctionalityConsumer } from "../hooks/functionalityConsumer";


export function ReturnHomeScreenCallPage(): JSX.Element {
  const data = useAppSelector((state: VRIAppStateType) => ({
    isLoggedIn: state.auth.isLoggedIn,
    userRole: state.auth.profile?.userRole,
    userId: state.auth.profile?.id
  }));
  const [upcomingScheduleDetails, setUpcomingScheduleDetails] = React.useState({});
  const [showUpcomingSchedule, setShowUpcomingSchedule] = useState<boolean>(false);

  // const { onGetConferenceList } = useFunctionalityConsumer()

  const onClickUpcomingSchedule = (schedule: ConsumerConferenceData) => {
    setUpcomingScheduleDetails(schedule);
    setShowUpcomingSchedule(true)
  };
  const consumerId = data?.userId;
  const { consumerConferenceList } = useFunctionalityConsumerConference(
    consumerId ? consumerId : ""
  );

  return (
    <>
      <TopNavPublic />
      <div className="position-fixed h-100 w-100 login-container-bg overflow-auto">
        <div className="container-md custom-container-lg position-relative h-100 ">
          <div className="row">
            <div className="col-12 my-md-4 py-md-4 mx-md-auto">
              <div className="component-card scheduler-tab-content my-4" id="pills-tabContent">
                <div className="component-card-body border-radius-xl px-0 py-4">
                  <UpcomingSchedule onClickUpcomingSchedule={onClickUpcomingSchedule} />
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
      <ModalCalendarUpcomingSchedule show={showUpcomingSchedule} handleShow={setShowUpcomingSchedule} scheduleInfo={upcomingScheduleDetails} />
    </>
  );
}
