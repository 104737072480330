import internal from "stream";
import { CallType } from "../../configs";

export type APISchemaCreateOperator = {
  email: string;
  firstName: string;
  lastName: string;
  companyId: string;
};

export type ApiSchemaOperatorPagedResponseData = {
  totalOnline: number;
  totalInCall: number;
  totalOperator: number;
  pageIndex: number;
  pageSize: number;
  count: number;
  operatorResponse: ApiSchemaOperatorResponseData[];
};

export type ApiSchemaOperatorResponseData = {
  id: string;
  vriAvailabilityStatusType: number;
  opiAvailabilityStatusType: number;
  isOnSiteEnabled: boolean;
  ratingScore?: number;
  ratingCount?: number,
  userId: string;
  name: string;
  email: string;
  operatorLanguages: string;
  operatorSkills: string;
  userStatusType: number;
  availableFrom?: string;
};

export enum OperatorStatusType {
  Online = 1,
  Offline = 2,
  InCall = 3,
  Busy = 4,
  Away = 5 ,
  Disabled = 6
}

export interface CustomField {
  name?: string;
  value?: string;
  isEnabled?: boolean;
  criteriaType?: number;
}

export interface ApiSchemaFilterOperatorRequest {
  parentCallId?:string;
  languageId?: string;
  languageName?:string;
  callType?:CallType;
  skillId?: string | null;
  gender?: number;
  callerPhone?: string;
  customFields?: CustomField[];
  department?: string | null;
  location?: string | null;
  CallForwardCount?:number|0;
}
 export interface FilterOperatorResponseModel {
  operatorId: string;
  callId: string;
  parentCallId: string;
  callForwardCount: number;
  message: string;
}
export enum OperatorCriteriaName {
  LANGUAGE='Language',
  SKILL='Skill',
  GENDER='Gender',
  DEPARTMENT='Department',
  LOCATION='Location',
  CUSTOMFIELD1='Custom Field 1',
  CUSTOMFIELD2='Custom Field 2',
  CUSTOMFIELD3='Custom Field 3',
  CUSTOMFIELD4='Custom Field 4',
}

export interface ApiSchemaFilterOperatorResponse {
  operatorId?: string;
  callId?: string;
}

export interface OperatorRatingRequest{
  time:Date,
  rating?:number,
  comment:string,
  callSummaryId:string,
  bookingSummaryId: string
}

export interface OperatorRatingUpdateRequest{
  time:Date,
  rating?:number,
  comment:string,
}

export interface ApiSchemaOperatorNames {
  id: string,
  name: string
}


export interface OperatorRatingResponseModel {
  id: string;
  callerId:string;
  callerName: string;
  callerCompanyName: string;
  callId: string;
  time: string;
  rating: number;
  comment: string;
  isEditable: boolean;
}

export interface ExistModel {
  data: boolean
}
