import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, PlatformApi, topLoaderProgress } from "../../configs";
import { AxiosAuth } from "../../core";
import {
    ApiResponse,
    WithPagination
} from "../../models";
import { OptionMapper, UtilsJQuery } from "../../utils";
import { ApiSchemaCallOutflowData, ApiCallOutflowSchemaChangeStatus } from "../../models/api/callOutflow";

let pagedOutflowUrl: string;

type CallOutflowProps = {
    companyId?: string;
    setLoaderProgress: any;
    pageSize: number;
    pageNo: number;
    searchText?: string;
};
export function useFunctionalityPagedCallOutflow(props: CallOutflowProps) {
    const { companyId, setLoaderProgress, pageSize, pageNo, searchText } = props;

    pagedOutflowUrl = PlatformApi.CallOutflow.GetOutflowListByCompanyId(
        companyId || "",
        pageSize,
        pageNo,
        searchText
    );

    const { data: apiCallOutflowData } = useSWR<{
        data: ApiSchemaCallOutflowData[];
        count: number;
    }>(

        pagedOutflowUrl,
        async () => {
            setLoaderProgress(topLoaderProgress.start);

            try {
                const response = await AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaCallOutflowData[]>>>(
                    pagedOutflowUrl
                );
                setLoaderProgress(topLoaderProgress.complete);

                return {
                    data: response.data.data.data,
                    count: response.data.data.count,
                };
            } catch (error) {
                setLoaderProgress(topLoaderProgress.complete);
                console.error(error);
                throw error;
            }
        }
    );

    const onChangeStatus = useCallback(async (form: ApiCallOutflowSchemaChangeStatus) => {
        const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
        btnLoading.start?.();
        try {
            const res = await AxiosAuth.put(PlatformApi.CallOutflow.ChangeCallOutflowStatus(), form);
            if ((res.status = AppConstants.Api.StatusOK)) {
                mutate(pagedOutflowUrl);
                toast.success("Status changed");
            }
        } catch (e) {
            toast.error("Failed to change status");
        }
        btnLoading.stop?.();
        return Promise.resolve(null);
    }, []);

    // const callOutflowData = React.useMemo(() => {
    //     const data = Array.isArray(apiCallOutflowData) ? apiCallOutflowData : [];
    //     return data;
    // }, [apiCallOutflowData]);

    const pagedcallOutflowList = useMemo(() => {
        return Array.isArray(apiCallOutflowData?.data) ? apiCallOutflowData.data : [];
    }, [apiCallOutflowData]);

    const pagedCallOuflowListCount = apiCallOutflowData?.count ?? 0;


    return {
        pagedcallOutflowList,
        pagedCallOuflowListCount,
        // callOutflowData,
        onChangeStatus,
    };
}